import { Dispatch, SetStateAction, createContext } from "react";
import { IAssetListItem } from "../../../../models";
import { IWorkspaceAssetListItem } from "../../../../bffmodels/IWorkspaceAssetListItem";
import { Schema } from "./Steps/SelectSchemaTable";

type SelectSchemaWizardConnection = {
  name: string;
  id: string;
};

export interface ISelectSchemaWizardContext {
  assetList: Schema[];
  selectedConnection: SelectSchemaWizardConnection;
  driverName: string | undefined;
  setSelectedConnection: (connection: SelectSchemaWizardConnection) => void;
  setAssetList: Dispatch<
    SetStateAction<(IWorkspaceAssetListItem | IAssetListItem)[]>
  >;
  setDriverName: (driverName: string) => void;
  existingCacheJobs?: string[];
}

export const SelectSchemaWizardContext =
  createContext<ISelectSchemaWizardContext>({
    assetList: null!,
    selectedConnection: null!,
    driverName: null!,
    setSelectedConnection: null!,
    setAssetList: null!,
    setDriverName: null!,
    existingCacheJobs: null!,
  });
