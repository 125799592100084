import { Chip, ChipProps } from "@mui/material";
import "./CDChip.scss";
import classNames from "classnames";

export type CDChipProps = ChipProps;

export function CDChip(props: CDChipProps) {
  const { className, ...chipProps } = props;

  return <Chip className={classNames("cdChip", className)} {...chipProps} />;
}
